const XLSX = require('xlsx')
const XLSX_STYLE = require('xlsx-style')

/**
 * 使用二维数组生成一个工作表
 */
const sheet_from_array_of_arrays = (data, opts) => {
    var ws = {};
    var range = {
        s: {
            c: 10000000,
            r: 10000000
        },
        e: {
            c: 0,
            r: 0
        }
    }

    for (var R = 0; R != data.length; ++R) {
        for (var C = 0; C != data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            var cell = {
                v: data[R][C]
            };
            if (cell.v == null) continue;
            var cell_ref = XLSX.utils.encode_cell({
                c: C,
                r: R
            })

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n';
                cell.z = XLSX.SSF._table[14];
                cell.v = date_num(cell.v);
                // cell.z = 'YYYY-MM-DD'
                cell.z = 'YYYY-MM-DD HH:mm:ss'
            } else cell.t = 's'

            ws[cell_ref] = cell
        }
    }

    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range)
    return ws
}

/**
 * 日期转换
 */
const date_num = (v, date1904) => {
    if (date1904) {
        v += 1462;
    }
    var epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

/**
 * 文件流转换
 */
const s2ab = (s) => {
    if (typeof ArrayBuffer !== 'undefined') {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf);
        for (let i = 0; i != s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff
        }
        return buf
    } else {
        const buf = new Array(s.length)
        for (let i = 0; i != s.length; ++i) {
            buf[i] = s.charCodeAt(i) & 0xff
        }
        return buf
    }
}

/**
 * 通用的打开下载对话框方法
 * @param url 下载地址，也可以是一个blob对象，必选
 * @param saveName 保持文件名，可选
 */
const openDownloadDialog = (url, saveName) => {
    if (typeof url == 'object' && url instanceof Blob) {
        // 创建blob地址
        url = URL.createObjectURL(url);
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    aLink.download = saveName || '';
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}

/**
 * 导出excel
 * @param data 要导出的数据
 * @param filename 导出文件名
 * @param wsCols 各单元格宽度
 */
export function export_to_excel(data, filename = "", wsCols = []) {
    // 新建一个工作簿
    let workBook = XLSX.utils.book_new()
    let workSheet = sheet_from_array_of_arrays(data)
    let len = data[1].length // 标题长度
    workSheet['!merges'] = [
        {
            s: { // s ("start"): c = 0 r = 0 -> "A1"
                r: 0,
                c: 0
            },
            e: { // e ("end"):   c = 0 r = 9 -> "J1"
                r: 0,
                c: len - 1
            }
        }
    ]

    // 设置每列的宽度（单位：px）
    let wchs = []
    if (wsCols) {
        for (let w of wsCols) {
            wchs.push({wch: w})
        }
    } else {
        for (let i = 0; i < len; i++) {
            wchs.push({wch: 20})
        }
    }
    workSheet['!cols'] = wchs

    // 设置每行的高度（单位：px）
    let wsRows = []
    for (let i in data) {
        if (i == 0) {
            wsRows.push({hpx: 60}) // 首行高度为 100px
        } else {
            wsRows.push({hpx: 30}) // 其他行高度为 30px
        }
    }
    workSheet['!rows'] = wsRows

    // 设置单元格样式
    for (let key in workSheet) {
        if (key == '!ref' || key == '!merges' || key == '!cols' || key == '!rows') {
            continue
        } else {
            // 匹配表格第一行（注意 A1 单元已合并为一个单元），设置其样式
            if (key == 'A1') {
                workSheet[key].s = { // 设置单元格样式
                    fill: { // 设置背景色
                        fgColor: { rgb: 'ffffff' },
                    },
                    font: { // 设置字体
                        name: '等线', // 字体名称
                        sz: 25, // 字体大小
                        bold: true, // 字体是否加粗
                        //color: { rgb: '5e7ce0' }, // 文字颜色
                    },
                    alignment: { // 设置居中
                        horizontal: 'center', // 水平（向左、向右、居中）
                        vertical: 'center', // 上下（向上、向下、居中）
                        wrapText: false, // 设置单元格自动换行，目前仅对非合并单元格生效
                        indent: 0 // 设置单元格缩进
                    },
                }
            }
            // 其它单元格，设置其样式
            else {
                workSheet[key].s = {
                    border: {
                        top: {
                            style: 'thin',
                        },
                        bottom: {
                            style: 'thin',
                        },
                        left: {
                            style: 'thin',
                        },
                        right: {
                            style: 'thin',
                        },
                    },
                    fill: { // 设置背景色
                        fgColor: {rgb: 'ffffff'},
                    },
                    font: { // 设置字体
                        name: '微软雅黑', // 字体名称
                        sz: 13, // 字体大小
                    },
                    alignment: {
                        horizontal: 'center', // 水平（向左、向右、居中）
                        vertical: 'center', // 上下（向上、向下、居中）
                        wrapText: false, // 设置单元格自动换行，目前仅对非合并单元格生效
                        indent: 0 // 设置单元格缩进
                    }
                }
            }
        }
    }
    XLSX.utils.book_append_sheet(workBook, workSheet, 'sheet1')
    const tmpDown = new Blob([
        s2ab(
            XLSX_STYLE.write(workBook, {
                bookType: 'xlsx',
                bookSST: true,
                type: 'binary',
                cellStyles: true,
            })
        )
    ], {
        type: "application/octet-stream"
    })
    openDownloadDialog(tmpDown, `${filename ? filename : "列表导出"}.xlsx`)
}

export function exportHcdjToExcel(data, filename = "", wsCols = []) {
    // 新建一个工作簿
    let workBook = XLSX.utils.book_new()
    let workSheet = XLSX.utils.aoa_to_sheet(data)
    // 设置每列的宽度（单位：px）
    let wchs = []
    if (wsCols) {
        for (let w of wsCols) {
            wchs.push({wch: w})
        }
    } else {
        for (let i = 0; i < len; i++) {
            wchs.push({wch: 20})
        }
    }
    workSheet['!cols'] = wchs

    // 设置每行的高度（单位：px）
    let wsRows = []
    for (let i in data) {
        if (i == 0) {
            wsRows.push({hpx: 60}) // 首行高度为 100px
        } else {
            wsRows.push({hpx: 30}) // 其他行高度为 30px
        }
    }
    workSheet['!rows'] = wsRows

    // 设置单元格样式
    for (let key in workSheet) {
        if (key == '!ref' || key == '!merges' || key == '!cols' || key == '!rows') {
            continue
        } else {
            workSheet[key].s = {
                border: {
                    top: {
                        style: 'thin',
                    },
                    bottom: {
                        style: 'thin',
                    },
                    left: {
                        style: 'thin',
                    },
                    right: {
                        style: 'thin',
                    },
                },
                font: { // 设置字体
                    name: '微软雅黑', // 字体名称
                    sz: 13, // 字体大小
                },
                alignment: {
                    horizontal: 'center', // 水平（向左、向右、居中）
                    vertical: 'center', // 上下（向上、向下、居中）
                    wrapText: false, // 设置单元格自动换行，目前仅对非合并单元格生效
                    indent: 0 // 设置单元格缩进
                }
            }
            if (key.indexOf('H') >= 0) {
                workSheet[key].s.fill = { // 设置背景色
                    fgColor: {rgb: 'ffff00'},
                }
            }
            if (key == 'B1' || key == 'C1' || key == 'D1' || key == 'E1' || key == 'H1') {
                workSheet[key].s.font.color = {rgb: 'ff0000'}
            }
        }
    }
    XLSX.utils.book_append_sheet(workBook, workSheet, 'sheet1')
    const tmpDown = new Blob([
        s2ab(
            XLSX_STYLE.write(workBook, {
                bookType: 'xlsx',
                bookSST: true,
                type: 'binary',
                cellStyles: true,
            })
        )
    ], {
        type: "application/octet-stream"
    })
    openDownloadDialog(tmpDown, `${filename ? filename : "列表导出"}.xlsx`)
}